<template>
  <div>
    <CCard style="margin:-25px">
          <CCardBody style="min-height:500px">
            <div class="row">
              <div class='col-7'>
                <div class="form-group row" style='margin-bottom:0.02rem'>
                  <label for="noref" class="col-sm-4 col-form-label">No. Ref</label>
                  <div class="col-sm-4" style="margin-top: 3px;">
                    <input type="text" class="form-control form-control-sm" id="noref" placeholder="No. Ref">
                  </div>
                  <div class="col-sm-4" style="margin-top: 3px;">
                    <input type="text" class="form-control form-control-sm" id="noref" placeholder="20/01/2022">
                  </div>
                </div>
                <div class="form-group row" style='margin-bottom:0.02rem'>
                  <label for="kodenamapelanggan" class="col-sm-4 col-form-label">Kode/Nama Pelanggan</label>
                  <div class="col-sm-3" style="margin-top: 3px;">
                    <input type="text" class="form-control form-control-sm" v-on:click="modalPelanggan = true"  id="kodenamapelanggan" placeholder="Kode">
                  </div>
                  <div class="col-sm-5" style="margin-top: 3px;">
                    <input type="text" class="form-control form-control-sm" v-on:click="modalPelanggan = true"  id="kodenamapelanggan" placeholder="Nama">
                  </div>
                </div>
                <div class="form-group row" style='margin-bottom:0.02rem'>
                  <label for="noref" class="col-sm-4 col-form-label">Alamat Pelanggan</label>
                  <div class="col-sm-8" style="margin-top: 3px;">
                    <input type="text" class="form-control form-control-sm" id="noref" placeholder="Alamat">
                  </div>
                </div>
                <div class="form-group row" style='margin-bottom:0.02rem'>
                  <label for="kodenamapelanggan" class="col-sm-4 col-form-label">Kode Kas</label>
                  <div class="col-sm-4" style="margin-top: 3px;">
                    <input type="text" class="form-control form-control-sm" id="kodenamapelanggan" placeholder="Kode">
                  </div>
                  <label for="kodenamapelanggan" class="col-sm-2 col-form-label text-right">SPG</label>
                  <div class="col-sm-2" style="margin-top: 3px;">
                    <input type="text" class="form-control form-control-sm" id="kodenamapelanggan" placeholder="Nama">
                  </div>
                </div>
                <div class="form-group row" style='margin-bottom:0.02rem'>
                  <label for="noref" class="col-sm-4 col-form-label">Pembayaran</label>
                  <div class="col-sm-4">
                    <select class="form-control form-control-sm">
                      <option value="" selected>Cash</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class='col-5 align-items-center'><span style="font-size:65pt">{{totals | currency}}</span></div>
            </div>
            <div >
            <table class="table table-bordered" id='brg'>
              <thead>
                <tr>
                  <th>Barang</th>
                  <th width="12%">Satuan</th>
                  <th width="7%">Qty</th>
                  <th width="13%">Harga</th>
                  <th width="7%">Dsc %</th>
                  <th width="12%">Dsc Rp</th>
                  <th width="13%">Subtotal</th>
                </tr>
              </thead>
              <tbody >
                <tr>
                  <td><div contenteditable>
                  <autocomplete
                    ref="autocomplete"
                    url="http://139.180.219.157/kasir/api/index"
                    anchor="product_name"
                    :classes="{ wrapper: 'form-wrapper', input: 'form-control', list: 'data-list', item: 'data-list-item' }"                    
                    param="q"
                    id="autocom"
                    label="writer"
                    :min="3"
                    :process = "processJSON"
                    :onSelect="getData">
                  </autocomplete>
                    </div></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr v-for="item in barangs" :key="item.pid">
                  <td>{{item.product_name}}</td>
                  <td>{{item.unit}}</td>
                  <td>{{qty}}</td>
                  <td>{{+item.product_price | currency}}</td>
                  <td>{{item.dscpersen}}</td>
                  <td>{{item.dscrp}}</td>
                  <td>{{(qty * item.product_price) | currency}}</td>
                </tr>
              </tbody>
            </table>
            </div>
            <CButton
                        color="primary"
                        class="px-4"
                        v-on:click="printNow"
                        >Print</CButton
                      >
          </CCardBody>
    </CCard>
    
    <CModal
      title="Transaksi Terakhir"
      size="lg"
      :show.sync="transaksiterakhir"
    >
      <table class="table" id="datatable">
          <thead>
            <tr>
              <th style="width:30px">#</th>
              <th>Nama </th>
              <th>Email </th>
              <th>Country </th>
              <th>Current Assets </th>
              <th>Last Activity </th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>

          </tbody>
        </table>  
    </CModal>

    <CModal
      title="Pelanggan"
      size="lg"
      :show.sync="modalPelanggan"
    >
      <table class="table" id="datatable">
          <thead>
            <tr>
              <th style="width:30px">#</th>
              <th>Nama </th>
              <th>Kode </th>
              <th>Telpon </th>
              <th>Alamat </th>
              <th>Limit </th>
            </tr>
          </thead>
          <tbody>

          </tbody>
        </table>  
    </CModal>
  </div>

  
</template>
<style scoped>
.col-form-label {
    display: flex;
    align-items: center;
}
/* tbody {
    display: block;
    height: 300px;
    overflow: auto;
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    /* even columns width , fix width of table too*
}
thead {
    width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width *
}
table {
    width: 100%;
} */
</style>

<script>
require('vue2-autocomplete-js/dist/style/vue2-autocomplete.css');
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";
import axios from 'axios';
// import TypeAhead from 'vue2-typeahead';
import Autocomplete from 'vue2-autocomplete-js'
if (localStorage.getItem("jwt") == null || localStorage.getItem("jwt") == "") {
  router.push({ path: "/login" });
}
export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    Autocomplete,
    WidgetsDropdown,
    WidgetsBrand,
  },
  data() {
    return {
      selected: "Month",
      msg: 'Welcome to Vue2-typeahead',
      data: '',
      transaksiterakhir:false,
      modalPelanggan : false,
      autoc: '',
      totals:0,
      barangs:[],
      showConfig: false,
      selectFirst: false,
      limit: 9999,
      queryParamName: ':keyword',
      minChars: 2,
      delayTime: 500,
      qty :1,
      dscrp : 0,
      dscpersen : 0,
      placeholder: 'Cari Barang',
      classes: 'typeahead',
      tableFields: [
        { key: "avatar", label: "", _classes: "text-center" },
        { key: "user" },
        { key: "country", _classes: "text-center" },
        { key: "usage", label:"Amount WD / Depo (%)" },
        { key: "payment", label: "Payment method", _classes: "text-center" },
        { key: "activity" },
      ],
    };
  },
  mounted(){  
    if(this.$store.state.labelhead=='Pos'){
        this.$store.commit('setLabelhead', 'Dashboard');
    }    
    else{
      this.$store.commit('setLabelhead', 'Pos');
    }
  },
  methods: {  
    processJSON(json) {
        return json;
    },
    printNow(){
      const user = {
        cetak: "Test Cetak",
        anggota: "Aminudin",
        faktur: "30129/2022",
        kasir: "Reni",
        tempo: "01/05/2022",
        tgl: "01/03/2022",
      };
      // alert(localStorage.getItem("jwt"));
      // if (
      //   (this.username == null || this.username == "",
      //   this.password == null || this.password == "")
      // ) {
      //   this.myModal = true;
      // } else {
        axios
          .post(
            "http://localhost:3001/",
            user
          )
          .then((response) => {
            // console.log(response);
            // alert(response.data);
            // this.tabs = response.data;/
            if (response.data == "ok") {
              alert("sukses cetak");
            } else {
              alert("gagal cetak");
            }
          });
      // }
    },
    getData(obj){
        // var my_object = {
        //   mail:this.mail,
        //   date:this.date,
        //   adress:this.adress,
        //   company: this.company,
        //   fliers: this.fliers
        // };
        this.totals += +obj.product_price * +this.qty;
        this.barangs.push(obj);
        this.$refs.autocomplete.setValue('');
        console.log(obj);
    },  
    // getResponse: function (response) {
    //   return response.data.data.items
    // },
    // onHit: function (item, vue, index) {
    //   vue.query = item;
    //   alert(index);
    // },
    // highlighting: function (item, vue) {
    //   return item.toString().replace(vue.query, `<b>${vue.query}</b>`)
    // },
    // render: function (items, vue) {
    //   // // 将搜索内容作为list的第一个
    //   // array.forEach(element => {
        
    //   // });
    //   this.barangs = items;
    //   const datas = [];
    
    //   items.forEach(function(data, index) {
    //     console.log("data :"+data.id);
    //     // /datas.push(data.name);
    //     datas[data.id] = data.name;
    //   });
    //   let newItem = [vue.query, ...datas];
    //   return newItem;
    // },
    // fetch: function (url) {
    //   return new Promise(resolve =>
    //     setTimeout(() => resolve(axios.get(url)), 1000)
    //   )
    // },
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
  },
  
};
</script>
